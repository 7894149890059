//import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const addOrUpdate = (data) => {
  return ajax.post(`${base}/api/itedu/v1/user-learn-plan/add-edit`, data);
};

const calendar = (hasFinish, fromTime, toTime) => {
  return ajax.get(
    `${base}/api/itedu/v1/user-learn-plan/calendar?hasFinish=${hasFinish}&fromTime=${fromTime}&toTime=${toTime}`
  );
};

const cosPrefix = () => {
  return ajax.get(`${base}/api/itedu/v1/user-learn-plan/cos-prefix`);
};

const get = (id) => {
  return ajax.get(`${base}/api/itedu/v1/user-learn-plan/get?id=${id}`);
};

const query = (hasFinish, fromTime, toTime) => {
  return ajax.get(
    `${base}/api/itedu/v1/user-learn-plan/query?hasFinish=${hasFinish}&fromTime=${fromTime}&toTime=${toTime}`
  );
};

const setFinish = (id) => {
  return ajax.post(`${base}/api/itedu/v1/user-learn-plan/set-finish?id=${id}`);
};

const cdnUrl = (cosKey) => {
  return ajax.get(`${base}/api/itedu/v1/user-learn-plan/cdn-url?cosKey=${cosKey}`);
};

export const userLearnPlanApi = {
  // import时得花括号明确
  addOrUpdate: addOrUpdate,
  calendar: calendar,
  cosPrefix: cosPrefix,
  get: get,
  query: query,
  setFinish: setFinish,
  cdnUrl: cdnUrl,
};
