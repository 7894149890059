<template>
  <div class="body">
    <div class="header-tool">
      <div class="label">
        <div class="icon" @click="changeCalendarMode()">
          <img :src="backIcon" />
        </div>
        <div class="year-month">{{ yearMonth }}</div>
      </div>
      <div class="tool">
        <img :src="leftIcon" style="margin-right:18px" @click="prevMonth()" />
        <img :src="rightIcon" @click="nextMonth()" />
      </div>
    </div>
    <div class="main">
      <div class="header">
        <div class="item" v-for="(item, index) in dayOfWeek" :key="index">
          {{ item }}
        </div>
      </div>
      <div class="day">
        <div class="item" v-for="(item, index) in monthDayList" :key="index">
          <div class="text">{{ item.date }}</div>
          <div v-if="item.dot" class="dot"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { userLearnPlanApi } from "@/api/userLearnPlanApi.js";
import { userHomeWorkApi } from "@/api/userHomeWorkApi.js";
export default {
  model: {
    prop: "queryTime",
    event: "onChangeQueryTime",
  },
  props: {
    queryTime: Object,
    initEpoch: Number,
    hasFinish: Boolean,
    bizType: String,
  },
  data() {
    return {
      backIcon: require("@/assets/images/user-learn-plan/back.png"),
      leftIcon: require("@/assets/images/user-learn-plan/arrow-left.png"),
      rightIcon: require("@/assets/images/user-learn-plan/arrow-right.png"),
      daysInMonth: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      year: 0,
      month: 0, // 月份是从 0 开始计算的，即 1 月是 0。
      // day: 0, //dayjs#date 是该月的日期。 dayjs#day 是星期几。从0(星期天)到6(星期六)
      firstDateOfMonth: null, // 接受1到31的数字
      dayOfWeek: ["日", "一", "二", "三", "四", "五", "六"],

      monthDayList: [],
      currentDay: null,
      yearMonth: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    let ts = this.initEpoch;
    // this.day = dayjs().day(); // 获取或设置星期几。
    // this.dayOfMonth = dayjs().date(); // 获取或设置月份里的日期。
    this.render(ts); // month 从0 开始
  },
  methods: {
    changeCalendarMode() {
      this.$emit("changeCalendarMode", "week");
    },
    getFirstDateOfMonth(year, month) {
      let tmpDate = new Date(year, month, 1);
      let firstDateOfMonth = dayjs(tmpDate);
      return firstDateOfMonth;
    },
    //本月的第一天
    getFirstDate(ts) {
      const day = dayjs(ts);
      const month = day.month();
      const year = day.year();
      let tmpDate = new Date(year, month, 1);
      let firstDateOfMonth = dayjs(tmpDate);
      return firstDateOfMonth;
    },
    //本月的最后一天
    getLastDate(ts) {
      const day = dayjs(ts);
      const month = day.month();
      const year = day.year();
      this.wrapDaysInMonth(year);
      let tmpDate = new Date(year, month, this.daysInMonth[month]);
      let lastDateOfMonth = dayjs(tmpDate);
      return lastDateOfMonth;
    },
    wrapDaysInMonth(year) {
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        this.daysInMonth[1] = 29;
      } else {
        this.daysInMonth[1] = 28;
      }
    },
    render(ts) {
      let today = dayjs(ts);
      this.yearMonth = today.format("YYYY年M月");
      this.currentDay = today;
      const month = this.currentDay.month();
      const year = this.currentDay.year();
      this.wrapDaysInMonth(year);

      const dayCount = this.daysInMonth[month];
      this.firstDateOfMonth = this.getFirstDateOfMonth(year, month);
      let dayOfWeek = this.firstDateOfMonth.day();

      let dayList = [];
      for (let i = 0; i < dayOfWeek; i++) {
        // 上个月的某几个星期几，padding补齐
        dayList.push({
          dayjsObj: null,
          date: "",
          dateInt: 0,
          dot: false,
        });
      }
      let dayjsItem = this.firstDateOfMonth;
      for (let i = 0; i < dayCount; i++) {
        dayList.push({
          dayjsObj: dayjsItem,
          date: dayjsItem.date(),
          dateInt: Number(dayjsItem.format("YYYYMMDD")),
          dot: false,
        });
        dayjsItem = dayjsItem.add(1, "day");
      }
      for (let i = dayCount; i < 35; i++) {
        dayList.push({
          dayjsObj: null,
          date: "",
          dateInt: 0,
          dot: false,
        });
      }
      this.monthDayList = dayList;

      let firstInMonth = this.getFirstDate(this.currentDay.valueOf());
      let lastInMonth = this.getLastDate(this.currentDay.valueOf());

      firstInMonth = dayjs(firstInMonth.format("YYYY-MM-DD") + " " + "00:00", "YYYY-MM-DD HH:mm");
      lastInMonth = dayjs(lastInMonth.format("YYYY-MM-DD") + " " + "23:59", "YYYY-MM-DD HH:mm");

      this.$emit("onChangeQueryTime", {
        start: {
          dayjsObj: firstInMonth,
          date: firstInMonth.date(),
          dateInt: Number(firstInMonth.format("YYYYMMDD")),
        },
        end: {
          dayjsObj: lastInMonth,
          date: lastInMonth.date(),
          dateInt: Number(lastInMonth.format("YYYYMMDD")),
        },
      }); //emit 事件，父组件更新，再传给子组件
      // userLearnPlanApi.calendar(this.hasFinish, firstInMonth.valueOf(), lastInMonth.valueOf()).then((ret) => {
      //   let dct = {};
      //   let { code, datas } = ret;
      //   if (code == 0 && datas) {
      //     for (let day of datas) {
      //       dct[day] = true;
      //     }
      //     for (let item of this.monthDayList) {
      //       if (dct[item.dateInt]) {
      //         item.dot = true;
      //       } else {
      //         item.dot = false;
      //       }
      //     }
      //     //console.log("dot", this.monthDayList);
      //   }
      // });
      // 组件复用，要么查询学习规划，要么查询作业
      if (this.bizType == "plan") {
        userLearnPlanApi.calendar(this.hasFinish, firstInMonth.valueOf(), lastInMonth.valueOf()).then((ret) => {
          let dct = {};
          let { code, datas } = ret;
          if (code == 0 && datas) {
            for (let day of datas) {
              dct[day] = true;
            }
            for (let item of this.monthDayList) {
              if (dct[item.dateInt]) {
                item.dot = true;
              } else {
                item.dot = false;
              }
            }
            //console.log("dot", this.weekDayList);
          }
        });
      } else {
        // hasFinish, 其实是hasReview
        userHomeWorkApi.calendar(this.hasFinish, firstInMonth.valueOf(), lastInMonth.valueOf()).then((ret) => {
          let dct = {};
          let { code, datas } = ret;
          if (code == 0 && datas) {
            for (let day of datas) {
              dct[day] = true;
            }
            for (let item of this.monthDayList) {
              if (dct[item.dateInt]) {
                item.dot = true;
              } else {
                item.dot = false;
              }
            }
          }
        });
      }
    },
    prevMonth() {
      let firstDate = this.getFirstDate(this.currentDay.valueOf());
      let prevMonthDate = firstDate.subtract(1, "day");
      this.render(prevMonthDate.valueOf());
    },
    nextMonth() {
      let nextDate = this.getLastDate(this.currentDay.valueOf());
      let nextMonthDate = nextDate.add(1, "day");
      this.render(nextMonthDate.valueOf());
    },
    onClose() {
      this.$EventBus.$emit("onCloseFullCalendar", true);
    },
    onSelectDate(item) {
      this.$EventBus.$emit("onDataFullCalendar", item);
      this.$EventBus.$emit("onCloseFullCalendar", true);
    },
  },
};
</script>

<style scoped lang="less">
.body {
  background: #ffffff;
  width: 344px;
  .header-tool {
    margin: 16px 12px 0px 12px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon {
        width: 25px;
        img {
          height: 17px;
          width: 9px;
        }
      }
      .year-month {
        font-size: 18px;
        color: #242424;
        letter-spacing: 0;
      }
    }
    .tool {
      img {
        height: 24px;
        width: 24px;
      }
    }
  }
  .main {
    margin: 0px 32px;
    .header {
      display: flex;
      flex-direction: row;
      .item {
        width: 40px;
        height: 40px;
        font-size: 14px;
        color: #9298a4;
        letter-spacing: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .day {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40px;
        height: 40px;
        .text {
          font-size: 16px;
          color: #242424;
          letter-spacing: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .dot {
          margin: 5px 0px 0px 0px;
          width: 6px;
          height: 6px;
          border-radius: 6px;
          background: #4d52d1;
        }
      }
    }
  }
}
</style>
