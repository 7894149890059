//import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const addOrUpdate = (data) => {
  return ajax.post(`${base}/api/itedu/v1/user-home-work/add-edit`, data);
};

const calendar = (hasReview, startTime, endTime) => {
  return ajax.get(
    `${base}/api/itedu/v1/user-home-work/calendar?hasReview=${hasReview}&fromTime=${startTime}&toTime=${endTime}`
  );
};

const cosPrefix = () => {
  return ajax.get(`${base}/api/itedu/v1/user-home-work/cos-prefix`);
};

const get = (id) => {
  return ajax.get(`${base}/api/itedu/v1/user-home-work/get?id=${id}`);
};

const query = (hasReview, startTime, endTime) => {
  return ajax.get(
    `${base}/api/itedu/v1/user-home-work/query?hasReview=${hasReview}&fromTime=${startTime}&toTime=${endTime}`
  );
};

const setFinish = (id) => {
  return ajax.post(`${base}/api/itedu/v1/user-home-work/set-finish?id=${id}`);
};

const cdnUrl = (cosKey) => {
  return ajax.get(`${base}/api/itedu/v1/user-home-work/cdn-url?cosKey=${cosKey}`);
};

const userOwnCourse = () => {
  return ajax.get(`${base}/api/itedu/v1/user/course`);
};

const awaitChain = (promise) => {
  return promise.then((data) => [data, null]).catch((err) => [null, err]);
};

export const userHomeWorkApi = {
  // import时得花括号明确
  addOrUpdate: addOrUpdate,
  calendar: calendar,
  cosPrefix: cosPrefix,
  get: get,
  query: query,
  setFinish: setFinish,
  cdnUrl: cdnUrl,
  userOwnCourse: userOwnCourse,
  awaitChain: awaitChain,
};
