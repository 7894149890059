<template>
  <div class="body">
    <div class="header-tool">
      <div class="year-month">{{ yearMonth }}</div>
      <div class="tool" @click="changeCalendarMode">
        <div>全部</div>
        <img :src="moreIcon" />
      </div>
    </div>
    <div class="calendar">
      <div class="side"><img :src="leftIcon" @click="prevWeek()" /></div>
      <div class="middle">
        <div class="day-header">
          <div class="item" v-for="(item, index) in dayOfWeek" :key="index">
            {{ item }}
          </div>
        </div>
        <div class="day">
          <div class="item" v-for="(item, index) in weekDayList" :key="index">
            <div class="text">{{ item.date }}</div>
            <div class="dot" v-if="item && item.dot == true"></div>
          </div>
        </div>
      </div>
      <div class="side"><img :src="rightIcon" @click="nextWeek()" /></div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { userLearnPlanApi } from "@/api/userLearnPlanApi.js";
import { userHomeWorkApi } from "@/api/userHomeWorkApi.js";
export default {
  model: {
    prop: "queryTime",
    event: "onChangeQueryTime",
  },
  props: {
    queryTime: Object,
    initEpoch: Number,
    hasFinish: Boolean,
    bizType: String,
  },
  data() {
    return {
      moreIcon: require("@/assets/images/user-learn-plan/more.png"),
      leftIcon: require("@/assets/images/user-learn-plan/arrow-left.png"),
      rightIcon: require("@/assets/images/user-learn-plan/arrow-right.png"),
      dayOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
      weekDayList: [28, 29, 30, 31, 1, 2, 3],
      currentDay: null,
      yearMonth: "",
    };
  },
  methods: {
    render(ts) {
      let today = dayjs(ts);
      this.yearMonth = today.format("YYYY年M月");
      this.currentDay = today;
      let dayOfWeek = today.day(); //星期几
      //console.log(dayOfWeek);
      let dayList = [];
      // 周级别的查询范围
      for (let i = 0, j = dayOfWeek; i <= dayOfWeek; i++, j--) {
        let dayjsObj = today.subtract(j, "day");
        let newObj = {
          dayjsObj: dayjsObj,
          date: dayjsObj.date(),
          dateInt: Number(dayjsObj.format("YYYYMMDD")),
          dot: false,
        };
        dayList.push(newObj);
      }
      for (let i = dayOfWeek + 1, j = 1; i <= 6; i++, j++) {
        let dayjsObj = today.add(j, "day");
        let newObj = {
          dayjsObj: dayjsObj,
          date: dayjsObj.date(),
          dateInt: Number(dayjsObj.format("YYYYMMDD")),
          dot: false,
        };
        dayList.push(newObj);
      }
      //console.log("dayList", dayList);
      this.weekDayList = dayList;
      //console.log("week day list", this.weekDayList);
      let startDay = this.weekDayList[0].dayjsObj;
      let endDay = this.weekDayList[this.weekDayList.length - 1].dayjsObj;
      startDay = dayjs(startDay.format("YYYY-MM-DD") + " " + "00:00", "YYYY-MM-DD HH:mm");
      endDay = dayjs(endDay.format("YYYY-MM-DD") + " " + "23:59", "YYYY-MM-DD HH:mm");

      this.weekDayList[0].dayjsObj = startDay;
      this.weekDayList[this.weekDayList.length - 1].dayjsObj = endDay;

      //console.log("before emit", startDay);

      this.$emit("onChangeQueryTime", {
        start: this.weekDayList[0],
        end: this.weekDayList[this.weekDayList.length - 1],
      }); //emit 事件，父组件更新，再传给子组件

      // 组件复用，要么查询学习规划，要么查询作业
      if (this.bizType == "plan") {
        userLearnPlanApi.calendar(this.hasFinish, startDay.valueOf(), endDay.valueOf()).then((ret) => {
          let dct = {};
          let { code, datas } = ret;

          if (code == 0 && datas) {
            for (let day of datas) {
              dct[day] = true;
            }
            for (let item of this.weekDayList) {
              if (dct[item.dateInt]) {
                item.dot = true;
              } else {
                item.dot = false;
              }
            }
            //console.log("dot", this.weekDayList);
          }
        });
      } else {
        // hasFinish, 其实是hasReview
        userHomeWorkApi.calendar(this.hasFinish, startDay.valueOf(), endDay.valueOf()).then((ret) => {
          let dct = {};
          let { code, datas } = ret;

          if (code == 0 && datas) {
            for (let day of datas) {
              dct[day] = true;
            }
            for (let item of this.weekDayList) {
              if (dct[item.dateInt]) {
                item.dot = true;
              } else {
                item.dot = false;
              }
            }
            //console.log("dot", this.weekDayList);
          }
        });
      }
    },
    prevWeek() {
      this.currentDay = this.currentDay.subtract(7, "day");
      this.render(this.currentDay.valueOf());
    },
    nextWeek() {
      this.currentDay = this.currentDay.add(7, "day");
      this.render(this.currentDay.valueOf());
    },
    changeCalendarMode() {
      this.$emit("changeCalendarMode", "month");
    },
  },
  mounted() {
    let ts = this.initEpoch;
    this.render(ts);
  },
  watch: {
    // dots() {
    //   console.log("dots update", this.dots);
    // },
  },
};
</script>

<style lang="less" scoped>
.body {
  background: #ffffff;
  width: 344px;

  .header-tool {
    height: 30px;
    margin: 0px 0px 12px 0px;
    font-size: 18px;
    color: #242424;
    letter-spacing: 0;
    line-height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .year-month {
      margin: 0 0 0 24px;
      font-size: 18px;
      color: #242424;
      letter-spacing: 0;
      line-height: 30px;
    }
    .tool {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      color: #9298a4;
      letter-spacing: 0;
      text-align: center;
      line-height: 30px;
      margin: 0 5px 0 0;
      img {
        margin: 0 0 0 5px;
        height: 11px;
        width: 6px;
      }
    }
  }
  .calendar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .side {
      width: 30px;
      margin: 0px 6px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .middle {
      flex: 1;
      display: flex;
      flex-direction: column;
      .day-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        color: #9298a4;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
        }
      }
      .day {
        margin: 6px 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 16px;
        color: #242424;
        letter-spacing: 0;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 24px;
          height: 36px;
          .dot {
            margin: 6px 0px 0px 0px;
            width: 6px;
            height: 6px;
            border-radius: 6px;
            background: #4d52d1;
          }
        }
      }
    }
  }
}
</style>
